.richText *:first-child {
  margin-top: 0;
}

.richText p {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.richText span {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.richText p {
  margin-top: var(--spacing-3);
  margin-bottom: var(--spacing-3);
  color: inherit;
}

.richText em {
  font-style: italic;
}

.richText h1,
.richText h2,
.richText h3,
.richText h4,
.richText h5,
.richText b,
.richText strong {
  font-weight: var(--font-weight-semi-bold);
}

.richText h2,
.richText h3,
.richText h4,
.richText h5 {
  margin-top: var(--spacing-5);
  margin-bottom: var(--spacing-3);
}

.richText h1 {
  font-size: 3.2rem;
  line-height: 4rem;
  margin-bottom: var(--spacing-5);
  margin-top: var(--spacing-9);
}

.richText h2 {
  font-size: 2.4rem;
  line-height: 3.2rem;
}

.richText h3 {
  font-size: 2rem;
  line-height: 2.8rem;
}

.richText h4,
.richText h5 {
  font-size: 2rem;
  line-height: 2.8rem;
}

.richText ul {
  margin-bottom: var(--spacing-3);
}

.richText ol {
  margin-bottom: var(--spacing-3);
}

.richText ul li {
  margin-bottom: var(--spacing-2);
  line-height: 2.4rem;
}

.richText ol li {
  margin-bottom: var(--spacing-2);
  line-height: 2.4rem;
}

.richText ul {
  margin-left: var(--spacing-5);
}

.richText ul li {
  list-style: none;
  position: relative;
}

.richText ul li::before {
  content: '';
  width: 8px;
  height: 8px;
  background-color: currentColor;
  border-radius: 50%;
  position: absolute;
  left: -30px;
  top: 8px;
}

.richText ol {
  margin-left: var(--spacing-5);
  counter-reset: list-counter;
}

.richText ol li {
  counter-increment: list-counter;
  list-style: none;
  position: relative;
}

.richText ol li::before {
  content: counter(list-counter) '.';
  position: absolute;
  left: -30px;
}

@media (min-width: var(--breakpoint-sm)) {
  .richText h1 {
    font-size: 5.6rem;
    line-height: 6.4rem;
    margin-top: var(--spacing-6);
  }

  .richText h2 {
    font-size: 3.2rem;
    line-height: 4rem;
  }

  .richText h3 {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }

  .richText ul li,
  .richText ol li {
    margin-bottom: var(--spacing-4);
  }
}

.content {
  color: var(--text-color);
}

.content p + p,
.content li + li {
  margin-top: var(--spacing-1);
}

.content ul,
.content ol {
  margin: var(--spacing-1) 0 var(--spacing-2) var(--spacing-3);
}

.cmsLink {
  --link-color: var(--color-blue);
  composes: linkBaseStyle from '@/components/LinkLookingButton/LinkLookingButton.module.css';
  color: var(--link-color);
}

.content :is(a:has(img):hover) {
  background-color: transparent;
}
